import React, { FC } from "react";
import { Link } from "gatsby";
import { Helmet } from "react-helmet";
import "./layout.scss";
import Footer from "./footer";

const Navbar: FC = () => (
  <nav className="navbar is-dark">
    <div className="container">
      <div className="navbar-brand">
        <Link to="/" className="navbar-item is-size-4 has-text-weight-semibold">
          CodeTime
        </Link>
      </div>
    </div>
  </nav>
);

export default ({ children }) => (
  <>
    <Helmet>
      <meta charSet="utf-8" />

      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:title" content="CodeTime" />
      <meta name="twitter:description" content="Wakatime Summaries for 70_10" />
      <meta name="twitter:image" content="/icon.jpg" />

      <title>CodeTime</title>
    </Helmet>
    <Navbar />
    {children}
    <Footer />
  </>
);
