import React from "react";
import Profile from "./profile";

export default () => (
  <footer className="footer">
    <div className="container">
      <div className="columns">
        <div className="column">
          <Profile />
        </div>
      </div>
    </div>
  </footer>
);
